import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import 'font-awesome/css/font-awesome.min.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const theme = {
  // primary: '#1976D2',
  // secondary: '#424242',
  // accent: '#82B1FF',
  // error: '#FF5252',
  // info: '#2196F3',
  // success: '#4CAF50',
  // warning: '#FFC107',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    // dark: true,
    themes: {
      dark: theme,
      light: {
        primary: colors.purple,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
    },
  },
  icons: {
    iconfont: 'fa4',
  },
})
