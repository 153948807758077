<template>
  <div>
    <router-view />
    <v-overlay
      :value="overlay"
      opacity="0.95"
      color="#000"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data () {
      return {
        overlay: false,
      }
    },
    watch: {
      $route () {
        if (window._czc) {
          const location = window.location
          const contentUrl = location.pathname + location.hash
          const refererUrl = '/'
          window._czc.push(['_trackPageview', contentUrl, refererUrl])
        }
      },
    },
    created () {
      this.overlay = this.$store.state.isShowLoading
    },
    mounted () {
      const script = document.createElement('scropt')
      script.src = 'https://s9.cnzz.com/z_stat.php?id=1279571571&web_id=1279571571'
      script.language = 'javascript'
      document.body.appendChild(script)
    },
    methods: {
    },
  }
</script>
