<template>
  <div>
    <v-app-bar
      class="d-none d-sm-flex"
      :src="bg"
      app
      elevate-on-scroll
      clipped-left
    >
      <img
        :src="systemLogo"
        alt="招商引资重点项目库"
        width="50px"
        height="50px"
      >
      <v-toolbar-title class="mr-12 align-center">
        <router-link
          to="/"
          class="web_name"
        >
          {{ systemName }}
        </router-link>
      </v-toolbar-title>
      <v-btn
        rounded
        color="warning"
        active-class="AppBar_btn_active"
        to="/"
      >
        返回首页
      </v-btn>
      <v-menu
        v-for="(formType, key) in formTypes"
        :key="key"
        offset-y
        open-on-hover
      >
        <template
          v-if="!orgQX"
          v-slot:activator="{ on, attrs }"
        >
          <v-btn
            class="mr-5"
            color="blue darken-4"
            v-bind="attrs"
            v-on="on"
            @click="toLink(formType.id)"
          >
            {{ formType.name }}
          </v-btn>
        </template>
      </v-menu>
      <v-spacer />
      <v-tooltip
        v-if="!orgQX"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            bordered
            color="green"
            :content="repeatLength"
            overlap
            offset-x="50"
            offset-y="15"
            :value="repeatShow"
          >
            <v-btn
              class="mr-10"
              fab
              dark
              depressed
              small
              color="#ff5722"
              to="/repeat-project-list"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                dark
              >
                fa fa-files-o
              </v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>重复项目列表</span>
      </v-tooltip>
      <v-tooltip
        v-if="isAdmin"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-10"
            fab
            dark
            depressed
            small
            color="#E53935"
            to="/delete-project-list"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              dark
            >
              fa fa-trash
            </v-icon>
          </v-btn>
        </template>
        <span>回收站</span>
      </v-tooltip>
      <v-tooltip
        v-if="isAdmin"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-10"
            fab
            dark
            depressed
            small
            color="blue darken-4"
            v-bind="attrs"
            v-on="on"
            @click="exportTable"
          >
            <v-icon
              dark
            >
              fa fa-download
            </v-icon>
          </v-btn>
        </template>
        <span>投促工作动态导出</span>
      </v-tooltip>
      <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-10"
          fab
          dark
          depressed
          small
          color="blue darken-4"
          v-bind="attrs"
          v-on="on"
          @click="downHelp"
        >
          <v-icon
            dark
          >
            fa fa-question-circle-o
          </v-icon>
        </v-btn>
      </template>
      <span>帮助手册下载</span>
    </v-tooltip>  -->
      <!--  <v-tooltip
      v-if="isadvance"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-10"
          fab
          dark
          depressed
          small
          color="#00D27E"
          v-bind="attrs"
          to="/problem-list"
          v-on="on"
        >
          <v-icon dark>
            mdi-calendar-arrow-right
          </v-icon>
        </v-btn>
      </template>
      <span>项目进展列表</span>
    </v-tooltip>
    <v-tooltip
      v-if="isAdmin"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-10"
          fab
          dark
          depressed
          small
          color="warning"
          to="/repeat-project-list"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            dark
          >
            fa fa-files-o
          </v-icon>
        </v-btn>
      </template>
      <span>重复项目列表</span>
    </v-tooltip>
    <v-tooltip
      v-permission="['ROLE_ADMIN','ROLE_SITEADMIN']"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-10"
          fab
          dark
          depressed
          small
          color="blue-grey lighten-1"
          to="/audit-doubtList"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            dark
          >
            fa fa-warning
          </v-icon>
        </v-btn>
      </template>
      <span>存疑项目列表</span>
    </v-tooltip>

    <v-tooltip
      v-if="isadvance"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bordered
          color="green"
          :content="unreadMessagesLength"
          overlap
          offset-x="50"
          offset-y="15"
          :value="unreadMessagesShow"
        >
          <v-btn
            class="mr-10"
            fab
            dark
            depressed
            small
            color="pink"
            v-bind="attrs"
            @click="dynamicDrawer()"
            v-on="on"
          >
            <v-icon
              dark
              small
            >
              fa4 fa-bell
            </v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span>最新消息</span>
    </v-tooltip>
    <v-tooltip
      v-if="false"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bordered
          color="green"
          :content="pendingLength"
          overlap
          offset-x="50"
          offset-y="15"
          :value="pendingShow"
        >
          <v-btn
            class="mr-10"
            fab
            dark
            depressed
            small
            color="#E91E63"
            to="/admin/pending-list"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              dark
              small
            >
              fa4 fa-bell
            </v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span>待审核项目</span>
    </v-tooltip> -->
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-avatar
            size="36"
            v-on="on"
          >
            <img
              v-if="userInfo"
              :src="userInfo.avatar ? userInfo.avatar : userPhoto"
              :alt="username"
            >
          </v-avatar>
        </template>
        <v-list>
          <v-list-item
            @click="user"
          >
            <v-list-item-title>
              <v-icon
                class="mx-4"
              >
                fa4 fa-user
              </v-icon>
              个人信息
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item
          v-permission="['ROLE_ADMIN','ROLE_SITE_ADMIN']"
          @click="fillIn"
        >
          <v-list-item-title>
            <v-icon
              class="mx-4"
            >
              fa4 fa-television
            </v-icon>
            管理后台
          </v-list-item-title>
        </v-list-item> -->
				<v-list-item
          @click="jump('/my-project-list')"
        >
          <v-list-item-title>
            <v-icon
              class="mx-4"
            >
              fa4 mdi-clipboard-text
            </v-icon>
            我的项目
          </v-list-item-title>
        </v-list-item>
          <v-list-item
            @click="changePassWord"
          >
            <v-list-item-title>
              <v-icon
                class="mx-4"
              >
                fa4 fa-unlock-alt
              </v-icon>
              修改密码
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="out"
          >
            <v-list-item-title>
              <v-icon
                class="mx-4"
              >
                fa4 fa-sign-out
              </v-icon>
              登出
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-switch
              v-model="audio"
              class="mr-8 mt-5"
              label="音效"
              color="light-blue darken-4"
              @click="changeAudio"
            />
          </v-list-item>
          <v-list-item>
            <v-switch
              v-model="$vuetify.theme.dark"
              class="mr-8 mt-5"
              label="夜晚"
              color="light-blue darken-4"
            />
          </v-list-item>
        </v-list>
      </v-menu>
      <message
        ref="messageDialog"
        @Messages="Messages"
      />
      <v-dialog
        v-model="isexport"
        width="480px"
      >
        <v-card>
          <v-toolbar
            color="info"
            dark
          >
            <v-toolbar-title class="mr-10">
              请选择导出的截止日期（默认当日）
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              :loading="exporting"
              elevation="1"
              class="ma-1"
              color="#0D47A1"
              @click="exportTable2"
            >
              导出
            </v-btn>
          </v-toolbar>
          <v-container
            style="height:320px"
          >
            <v-row class="ma-4">
              <DatePicker
                v-model="createData"
                type="date"
                placement="right-start"
                placeholder="日期"
              />
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-app-bar>

    <v-app-bar
      class="d-flex d-sm-none justify-space-between"
      :src="bg"
      app
      elevate-on-scroll
      clipped-left
    >
      <v-toolbar-title class="mr-2 align-center">
        <router-link
          to="/"
          class="web_name"
        >
          {{ systemName }}
        </router-link>
      </v-toolbar-title>
      <v-spacer class="" />

      <v-avatar
        size="36"
        @click="changeMenu(true)"
      >
        <v-icon
          class="mx-4"
          color="#fff"
        >
          fa4 mdi-format-list-bulleted
        </v-icon>
      </v-avatar>

      <v-menu
        offset-y
        :close-on-content-click="false"
        content-class="menus"
        :value="$store.state.isMenu"
      >
        <div
          class="alls"
        >
          <div
            class="lefts"
            @click.stop="changeMenu(false)"
          />
          <v-list
            color="#F5F5F5"
            class="lists"
          >
            <v-list-item
              class="pt-4 pb-3"
              @click="user"
            >
              <v-list-item-title>
                <div
                  class="d-flex justify-center"
                >
                  <v-avatar
                    size="56"
                  >
                    <img
                      v-if="userInfo"
                      :src="userInfo.avatar ? userInfo.avatar : userPhoto"
                      :alt="username"
                    >
                  </v-avatar>
                </div>
                <div
                  class="d-flex justify-center mt-3 font-weight-bold"
                >
                  <span>{{ username }}</span>
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-list-item-group class="groups mb-3">
              <v-list-item
                v-for="(formType, key) in formTypes"
                :key="key"
                class="pa-0 itemOne"
                @click="toLink(formType.id)"
              >
                <v-list-item-title class="pl-3">
                  <!-- <v-icon
                    class="mx-4"
                  >
                    {{ item.icon }}
                  </v-icon> -->
                  {{ formType.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group class="groups mb-3">
              <v-list-item
                v-if="isAdmin"
                class="pa-0 itemOne"
                @click="exportTable()"
              >
                <v-list-item-title>
                  <v-icon
                    class="mx-4"
                  >
                    fa fa-download
                  </v-icon>
                  投促工作动态导出
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!orgQX"
                class="pa-0 itemOne"
                @click="jump('/repeat-project-list')"
              >
                <v-list-item-title>
                  <v-icon
                    class="mx-4"
                  >
                    fa fa-files-o
                  </v-icon>
                  重复项目列表
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isAdmin"
                class="pa-0 itemOne"
                @click="jump('/delete-project-list')"
              >
                <v-list-item-title>
                  <v-icon
                    class="mx-4"
                  >
                    fa fa-trash
                  </v-icon>
                  回收站
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isAdmin"
                class="pa-0 itemOne"
                @click="jump('/pages/password')"
              >
                <v-list-item-title>
                  <v-icon
                    class="mx-4"
                  >
                    fa fa-unlock-alt
                  </v-icon>
                  修改密码
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group class="mt-6 mb-2">
              <v-list-item
                class="pa-0"
                @click="out"
              >
                <v-list-item-title>
                  <v-btn
                    depressed
                    color="#1976D2"
                    class="ma-0"
                    style="width:100%;height:100%"
                  >
                    退出登录
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-menu>
      <message
        ref="messageDialog"
        @Messages="Messages"
      />
      <v-dialog
        v-model="isexport"
        width="480px"
      >
        <v-card>
          <v-toolbar
            color="info"
            dark
          >
            <v-toolbar-title class="mr-10">
              请选择导出的截止日期（默认当日）
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              :loading="exporting"
              elevation="1"
              class="ma-1"
              color="#0D47A1"
              @click="exportTable2"
            >
              导出
            </v-btn>
          </v-toolbar>
          <v-container
            style="height:320px"
          >
            <v-row class="ma-4">
              <DatePicker
                v-model="createData"
                type="date"
                placement="right-start"
                placeholder="日期"
              />
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'
  import userPhoto from '../../assets/user_photo.jpg'
  import logo from '../../assets/logo.png'
  import bg from '../../assets/top_bg.png'
  import message from '../../views/list/Message'
  export default {
    components: {
      message,
    },
    data () {
      return {
        isexport: false,
        exporting: false,
        createData: '',
        pendingShow: false,
        pendingLength: 0,
        unreadMessagesLength: 1,
        unreadMessagesShow: false,
        logo: logo,
        systemLogo: logo,
        systemName: '招商引资重点项目库',
        username: '',
        userPhoto: userPhoto,
        userInfo: {},
        publicPath: process.env.BASE_URL,
        audio: false,
        reportForms: [],
        carrierForms: [],
        bg: bg,
        formTypes: [],
        isadvance: false,
        isAdmin: false,
        orgQX: false,
        repeatLength: 0,
        repeatShow: false,
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        // 客户
        kuFormId: 'ad326bff-dcd0-4610-95c2-3a2d6c4f165a',
        // 外商寻求
        wqFormId: 'eeadced3-7c53-4e4d-9f17-a334a28e10ad',
        isDeputy: false,
        isEdit: false,
      }
    },
    created () {
      this.isEdit = localStorage.isEdit === 'true' || localStorage.isEdit === true
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
      this.isAdmin = localStorage.isAdmin === 'true' || localStorage.isAdmin === true
      this.isDeputy = localStorage.isDeputy === 'true' || localStorage.isDeputy === true
      // this.isadvance = JSON.parse(localStorage.getItem('systemConfig')).advance
      this.systemLogo = localStorage.systemLogo
      this.systemName = localStorage.systemName
      document.title = localStorage.systemName
      this.getFormType()
      this.gerUserName()
      this.username = localStorage.userName
      this.getMeInfo()
      if (localStorage.audio) {
        this.audio = localStorage.audio === 'true'
      } else {
        localStorage.audio = false
      }
      this.getPending()
      this.getProjectList()
    },
    methods: {
      changeMenu (type) {
        console.log('type', type);
        if (this.$store.state.isMenu === type && type === true) {
          this.$store.commit('SET_Menu', false)
          setTimeout(() => {
            this.$store.commit('SET_Menu', type)
          }, 100);
        } else {
          this.$store.commit('SET_Menu', type)
        }
      },
      getPending () {
        this.$axios.get('/reports/audit2', {
          page: 0,
          size: 15,
        })
          .then(res => {
            this.pendingLength = res.data.data.totalElements
            if (this.pendingLength > 0) {
              this.pendingShow = true
            }
          })
      },
      getProjectList () { // 获取重复项目列表
        const formIds = []
        this.formTypes.map(val => {
          formIds.push(val.id)
        })
        this.$axios.get('/reports/duplicate', {
          params: {
            formId: formIds.toString(),
          },
        }).then(async (res) => {
          this.repeatLength = parseInt(res.data.data.length / 2)
          if (this.repeatLength > 0) {
            this.repeatShow = true
          }
        })
      },
      dynamicDrawer: function (item) {
        this.$refs.messageDialog.open(item)
      },
      Messages (val) {
        this.unreadMessagesLength = val.length
        this.unreadMessagesShow = val.show
      },
      getFormType () {
        if (JSON.parse(localStorage.newForms).length) {
          this.formTypes = JSON.parse(localStorage.newForms)[0].children
        }
      },
      toLink (formId) {
        const searchParame = { _from: 'org' }
        if (formId === this.wsFormId && this.orgQX) {
          this.$router.push({
            path: '/city-list',
          })
        } else if (formId === this.tjFormId && this.isDeputy) {
          this.$router.push({
            path: '/city-list2',
          })
        } else {
          if (formId === this.tjFormId && this.isEdit) {
            searchParame['aStatusList'] = '0,10'
            searchParame['_from'] = 'audit'
            searchParame['statusList'] = '5,10,0'
            // 领导临时查看
            // searchParame['isShow'] = '是'
          } else {
            delete searchParame['aStatusList']
            delete searchParame['statusList']
            searchParame['_from'] = 'org'
          }
          this.$router.push({
            path: `/list?formId=${formId}`,
            query: searchParame,
          })
        }
      },
      changeAudio () {
        localStorage.audio = this.audio
      },
      gerUserName () {
        if (this.$store.state.userName) {
          this.username = this.$store.state.userName
        } else {
          if (localStorage.username) {
            this.username = localStorage.username
          } else {
            this.username = 'NAME'
          }
        }
      },
      getMeInfo () {
        this.$axios.get('/me')
          .then(res => {
            this.userInfo = res.data.data
          })
          .catch(err => {
            console.log(err)
          })
      },
      user () {
        this.$router.push('/pages/user')
      },
      changePassWord () {
        this.$router.push('/pages/password')
      },
      out () {
        localStorage.clear()
        Cookies.remove()
        this.$router.push('/pages/login')
      },
      fillIn () {
        this.$router.push('/admin')
      },
      Statistics () {
        this.$router.push('/statistics')
      },
      StatisticsWord () {
        this.$router.push('/statistics-word')
      },
      downHelp () {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = `${this.publicPath}山东省对外招商引资重点项目库用户手册V1.0.pdf`
        link.target = '_blank'
        // link.setAttribute('download', `${fileName}.${suffix}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      jump (path) {
        this.$router.push(path)
      },
      is_weixin () {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
          console.log('微信浏览器');
          return true;
        } else {
          console.log('不是微信浏览器');
          return false;
        }
      },
      // 导出
      exportTable () {
        if (this.is_weixin()) {
          this.$swal({
            title: '点击右上角选择在浏览器中打开!',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          this.isexport = true

        // this.$axios.get('/foreignInvestment/reportdoc/export')
        //   .then(res => {
        //     const resData = res.config.url
        //     this.exportFile(resData)
        //   })
        //   .catch(err => {
        //     console.log(err)
        //   })
        }
      },
      exportTable2 () {
        this.exporting = true
        var data = this.createData ? this.$moment(this.createData).format('YYYY-MM-DD HH:mm:ss') : this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.$axios.get(`/foreignInvestment/reportdoc/export?date=${data}`)
          .then(res => {
            const resData = res.config.url
            this.exportFile(resData)
          })
          .catch(err => {
            console.log(err)
          })
      },
      // 导出文件
      async exportFile (url) {
        const data = await this.$axios.get(url, {
          responseType: 'blob',
        })
        const link = document.createElement('a')
        // link.style.display = 'none'
        link.href = window.URL ? window.URL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' })) : window.webkitURL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' }))
        link.setAttribute('download', '投促工作动态.doc')
        document.body.appendChild(link)
        link.click()
        link.remove()
        setTimeout(() => {
          this.exporting = false
          this.isexport = false
        }, 800);
      },
    },
  }
</script>

<style lang="scss" scoped>
.web_name{
  color: #fff;
  text-decoration: none;
}
.top_bg{
  background: url(../../assets/top_bg.png);
  background-size: 100%;
}
.AppBar_btn_active{
  background: #f00;
}::v-deep #disActive div:last-child{
border-bottom: none !important;
}
.menus{
top: 0 !important;
right: 0 !important;
left: auto !important;
max-width: 100% !important;
width: 100%;
height: 100%;
min-height: 800px;
}
.alls{
display: flex;
height: 100%;
min-height: 800px;
}
.lefts{
flex: 1;
opacity: 0.5;
background: #000000;
height: 100%;
min-height: 800px;
}
.lists{
width: 176px;
padding: 0 12px;
background: #f5f5f5;
height: 100%;
min-height: 800px;
}
.groups{
width: 176px;
background: #ffffff;
border-radius: 10px;
::v-deep .v-input--switch .v-input__control .v-input__slot .v-label{
color:#333333
}
}
.itemOne{
::v-deep .v-list-item__title{
color:#333333;font-weight: 500;
}
}
::v-deep .v-app-bar .v-toolbar__content{
width:100%;
}
</style>
