<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          请尽快完善您的个人信息
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(r, index) in required"
              :key="index"
            >
              <v-list-item-icon
                v-if="r.isNull"
              >
                <v-icon color="pink">
                  fa fa-close
                </v-icon>
              </v-list-item-icon>
              <v-list-item-icon
                v-if="!r.isNull"
              >
                <v-icon color="success">
                  fa fa-check-square-o
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="r.text" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            block
            to="/pages/user"
          >
            立即去填写
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
        // required: ['avatar', 'mobile', 'nickname', 'office', 'motto'],
        required: [
          {
            code: 'avatar',
            text: '头像',
            isNull: false,
          },
          {
            code: 'mobile',
            text: '手机号',
            isNull: false,
          },
          {
            code: 'nickname',
            text: '姓名',
            isNull: false,
          },
          {
            code: 'office',
            text: '单位',
            isNull: false,
          },
          {
            code: 'motto',
            text: '职位',
            isNull: false,
          },
        ],
      }
    },
    created () {
      this.$axios.get('/me')
        .then(res => {
          const resData = res.data.data
          this.required.map((val, index) => {
            if (resData[val.code] === null || resData[val.code] === '') {
              this.dialog = true
              this.required[index].isNull = true
            }
          })
        })
    },
  }
</script>

<style lang="scss" scoped>

</style>
