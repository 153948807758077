<template>
  <div class="audioBox">
    <audio
      ref="audioClick"
      :src="publicPath + 'audio/click.mp3'"
      controls
      hidden="true"
    />
    <audio
      ref="audioSuccess"
      :src="publicPath + 'audio/success.mp3'"
      controls
      hidden="true"
    />
    <audio
      ref="audioError"
      :src="publicPath + 'audio/error.mp3'"
      controls
      hidden="true"
    />
    <audio
      ref="audioPopping"
      :src="publicPath + 'audio/popping.mp3'"
      controls
      hidden="true"
    />
  </div>
</template>

<script>
  export default {
    data () {
      return {
        publicPath: process.env.BASE_URL,
      }
    },
    methods: {
      audioPlay (type) {
        if (localStorage.audio === 'true') {
          switch (type) {
            case 'success':
              this.$refs.audioSuccess.play()
              break
            case 'error':
              this.$refs.audioError.play()
              break
            case 'popping':
              this.$refs.audioPopping.play()
              break
            default:
              this.$refs.audioClick.play()
          }
        }
      },
    },
  }
</script>
<style scoped>
  .audioBox {
    position: absolute;
    width: 0px;
    height: 0px;
  }
</style>
