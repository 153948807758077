// import Vue from 'vue'
import Vuex from 'vuex'

// Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
    isAdmin: false,
    reserve: 0,
    negotiation: 0,
    signing: 0,
    implementation: 0,
    userName: '',
    searchParams: null,
    forms: null,
    orgs: null,
    userGroups: null,
    roles: null,
    isShowLoading: false,
    isMenu: false,
  },
  mutations: {
    SET_ISSHOWLOADING (state, show) {
      state.isShowLoading = show
    },
    SET_ORGS (state, orgs) {
      state.orgs = orgs
    },
    SET_USERGROUPS (state, userGroups) {
      state.userGroups = userGroups
    },
    SET_ROLES (state, roles) {
      state.roles = roles
    },
    SET_TOKEN (state, token) {
      state.Token = token
    },
    SET_FORMOBJ (state, forms) {
      state.forms = forms
    },
    SET_SEARCH_PARAMS (state, searchParams) {
      state.searchParams = searchParams
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    SET_ADMIN (state, isadmin) {
      state.isAdmin = isadmin
    },
    SET_Reserve (state, reserve) {
      state.reserve = reserve
    },
    SET_Negotiation (state, negotiation) {
      state.negotiation = negotiation
    },
    SET_Signing (state, signing) {
      state.signing = signing
    },
    SET_Implementation (state, implementation) {
      state.implementation = implementation
    },
    SET_UserName (state, username) {
      state.userName = username
    },
    SET_Menu (state, isMenu) {
      state.isMenu = isMenu
    },
  },
  actions: {

  },
})
