// import Vue from 'vue'
import Router from 'vue-router'

// Vue.use(Router)
const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [{
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [{
        name: 'Login',
        path: 'login',
        component: () => import('@/views/pages/Login'),
      }],
    },
    {
      path: '/admin',
      component: () => import('@/admin/Index'),
      children: [{
          name: 'Dashboard',
          path: 'dashboard',
          component: () => import('@/admin/dashboard/Index'),
        },
        {
          name: 'Report2',
          path: 'report',
          component: () => import('@/admin/report/Index'),
        },
        {
          name: 'FormType',
          path: 'form-type',
          component: () => import('@/admin/form/Index'),
        },
        {
          name: 'FormCreate',
          path: 'form-create',
          component: () => import('@/admin/form/Create'),
        },
        {
          name: 'Form',
          path: 'form',
          component: () => import('@/admin/form/Form'),
        },
        {
          name: 'Dictionary',
          path: 'dictionary',
          component: () => import('@/admin/form/Dictionary'),
        },
        {
          name: 'Article',
          path: 'article',
          component: () => import('@/admin/article/Index'),
        },
        {
          name: 'ArticleEdit',
          path: 'article-edit',
          component: () => import('@/admin/article/ArticleEdit'),
        },
        {
          name: 'Column',
          path: 'column',
          component: () => import('@/admin/article/Column'),
        },
        {
          name: 'User',
          path: 'user',
          component: () => import('@/admin/user/Index'),
        },
        {
          name: 'RegisterApply',
          path: 'register-apply',
          component: () => import('@/admin/user/RegisterApply'),
        },
        {
          name: 'UserGroup',
          path: 'user-group',
          component: () => import('@/admin/user/UserGroup'),
        },
        {
          name: 'Role',
          path: 'role',
          component: () => import('@/admin/user/Role'),
        },
        {
          name: 'Organization',
          path: 'organization',
          component: () => import('@/admin/user/Organization'),
        },
        {
          name: 'WechatSetup',
          path: 'wechat-setup',
          component: () => import('@/admin/wechat/Setup'),
        },
        {
          name: 'WechatCustommenu',
          path: 'wechat-custommenu',
          component: () => import('@/admin/wechat/Custommenu'),
        },
        {
          name: 'MessageSettings',
          path: 'wechat-messagesettings',
          component: () => import('@/admin/wechat/MessageSettings'),
        },
        {
          name: 'Map',
          path: 'map',
          component: () => import('@/admin/map'),
        },
        {
          name: 'CensusList',
          path: 'censusList',
          component: () => import('@/views/list/CensusList'),
        },
         {
          name: 'PendingList',
          path: 'pending-list',
          component: () => import('@/views/list/PendingList'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          redirect: '/dashboard',
          // redirect: () => {
          //     const orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
          //     console.log('orgQX', orgQX);
          //     if (orgQX) {
          //       return '/city-list'
          //     } else {
          //       return '/dashboard'
          //     }
          // },
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'dashboard',
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'Statistics',
          path: 'statistics',
          component: () => import('@/views/statistics/Statistics'),
        },
        {
          name: 'StatisticsWord',
          path: 'statistics-word',
          component: () => import('@/views/statistics/StatisticsWord'),
        },
        {
          name: 'ExamineList',
          path: 'examine-list/:status?',
          component: () => import('@/views/list/ExamineList'),
        },
        {
          name: 'ExamineList2',
          path: 'examine-list2',
          component: () => import('@/views/list/ExamineList2'),
        },
        {
          name: 'List',
          path: 'list',
          component: () => import('@/views/list/List'),
        },
        {
          name: 'CityList',
          path: 'city-list',
          component: () => import('@/views/list/CityList'),
        },
        {
          name: 'CityList2',
          path: 'city-list2',
          component: () => import('@/views/list/CityList2'),
        },
        {
          name: 'FormCreated',
          path: 'form-created',
          component: () => import('@/views/form/FormCreated'),
        },
        {
          name: 'TagProjectList',
          path: 'tagprojectlist/:tag',
          component: () => import('@/views/list/TagProjectList'),
        },
        {
          name: 'ProblemList',
          path: 'problem-list',
          component: () => import('@/views/list/ProblemList'),
        },
        {
          name: 'RepeatProjectList',
          path: 'repeat-project-list',
          component: () => import('@/views/list/RepeatProjectList'),
        },
        {
          name: 'AuditDoubtList',
          path: 'audit-doubtList',
          component: () => import('@/views/list/AuditDoubtList'),
        },
        {
          name: 'Report',
          path: 'report',
          component: () => import('@/views/report/Report'),
        },
        {
          name: 'ReportWs',
          path: 'report-ws',
          component: () => import('@/views/report/ReportWs'),
        },
        {
          name: 'ReportDetails',
          path: 'report-details/:formId/:id',
          component: () => import('@/views/report/ReportDetails'),
        },
        {
          name: 'DynamicDetails',
          path: 'dynamic-details/:formId/:id',
          component: () => import('@/views/report/DynamicDetails'),
        },
        {
          name: 'UserProfile',
          path: 'pages/user',
          component: () => import('@/views/user/UserProfile'),
        },
        {
          name: 'PassWord',
          path: 'pages/password',
          component: () => import('@/views/user/PassWord'),
        },
        {
          name: 'DeleteProjectList',
          path: 'delete-project-list/:page?',
          component: () => import('@/views/list/DeleteProjectList'),
        },
        {
          name: 'MyProjectList',
          path: 'my-project-list/:page?',
          component: () => import('@/views/list/MyProjectList'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [{
        name: '404 Error',
        path: '',
        component: () => import('@/views/pages/Error'),
      }],
    },
  ],
})
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }

  const token = localStorage.token // 获取token
  if (token) {
    next()
  } else {
    if (to.path !== '/pages/login') { // 必须判断是否进入路由，否则陷入死循环
      next('/pages/login')
    } else {
      next()
    }
  }
})
export default router
