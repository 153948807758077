const permission = {
  // 除了这几个角色其他的显示
  inserted: function (el, binding) {
    const elRoles = binding.value;
    const userRoles = localStorage.getItem('roles').split(',');
    const role = elRoles.find(item => userRoles.includes(item));
    console.log('role', role, 'userRoles', userRoles);
    if (role) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
};
export default permission;
