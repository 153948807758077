// 用户组管理
import base from './base'
import axios from './http'
const groups = {
  addGroups (groupsInfo) {
    return axios.post(`${base.sq}/groups`, groupsInfo)
  },
  getGroups (id) {
    return axios.get(`${base.sq}/groups/admin`, {
      type: 'role',
    })
  },
  delGroups (id) {
    return axios.delete(`${base.sq}/groups/${id}`)
  },
  editGroups (id, groupsInfo) {
    return axios.put(`${base.sq}/groups/${id}`, groupsInfo)
  },
}
export default groups
