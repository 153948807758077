<template>
  <div>
    <v-row :class="isXs?'titleSearch':''">
      <v-col
        v-show="isAdd"
        v-notPermission="['ROLE_TZCJCNBYJ','ROLE_KFQCNBYJ','ROLE_TZCJCFZRSHYJ']"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-btn
          elevation="1"
          color="blue mt-0"
          style="max-width:100%;"
          @click="addProjcet"
        >
          <v-icon left>
            fa4 fa-plus
          </v-icon>
          添加
        </v-btn>
      </v-col>
      <!--  <v-col
        v-if="$route.query.formId === tjFormId && orgQX"
        md="1"
      >
        <v-select
          v-model="status"
          :items="states"
          label="项目状态"
          item-text="text"
          item-value="value"
          solo
          dense
          clearable
        />
      </v-col> -->
      <!-- <v-col
        v-if="isForm"
        md="1"
      >
        <v-select
          v-model="formId"
          :items="formTypes"
          label="信息列表"
          item-text="name"
          item-value="id"
          solo
          dense
        />
      </v-col> -->
      <v-col
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-text-field
          v-model="projectName"
          label="名称"
          solo
          dense
          clearable
        />
      </v-col>
      <v-col
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
        v-if="$route.query.formId === kuFormId "
      >
        <v-text-field
          
          v-model="customerName"
          label="公司名称"
          solo
          dense
          clearable
        />
      </v-col>
      <v-col
        v-if="$route.query.formId === wsFormId || $route.query.formId === kuFormId"
        cols="8"
        sm="4"
        md="2"
        lg="2"
        xl="2"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <DatePicker
          v-model="createData"
          style="cursor:pointer;width: 100%;height:38px;box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
          type="daterange"
          placement="bottom-end"
          placeholder="创建时间"
        />
      </v-col>
      <v-col
        v-if="$route.query.formId === tjFormId ||$route.query.formId === wsFormId || $route.query.formId === kuFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-select
          v-model="industry"
          clearable
          :items="industrys"
          label="产业类别"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col>

      <v-col
        v-if="$route.query.formId === wsFormId||$route.query.formId === kuFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-autocomplete
          v-model="country"
          :items="countrys"
          clearable
          dense
          item-text="name"
          item-value="code"
          :label="$route.query.formId === wsFormId?'国别地区':'国籍'"
          solo
        />
      </v-col>
      <v-col
        v-if="$route.query.formId === tjFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-autocomplete
          v-model="city"
          :items="citys"
          clearable
          dense
          item-text="name"
          item-value="code"
          label="所属区县"
          solo
        />
      </v-col>
      <v-col
        v-if="$route.query.formId === wsFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-select
          v-model="cooperationMode"
          clearable
          :items="cooperationModes"
          label="合作方式"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col>
      <v-col
        v-if="$route.query.formId === wsFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-select
          v-model="isManufacturingProject"
          clearable
          :items="isManufacturingProjects"
          label="制造业项目"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col>
      <v-col
        v-if="$route.query.formId === wsFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-select
          v-model="investmentScale"
          clearable
          :items="investmentScales"
          label="投资规模"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col>
      <!-- <v-col
        v-if="$route.query.formId === wsFormId"
        md="1"
      >
        <v-select
          v-model="projectStatus"
          clearable
          :items="projectStatuss"
          label="项目状态"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col> -->
      <v-col
        v-if="$route.query.formId === kuFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-select
          v-model="customerLevel"
          clearable
          :items="customerLevels"
          label="客户级别"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col>
      <v-col
        v-if="$route.query.formId === kuFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-autocomplete
          v-model="headquarters"
          clearable
          :items="headquarterss"
          label="总部注册国家/地区"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col>
      <v-col
        v-if="$route.query.formId === kuFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-select
          v-model="companyCategory"
          clearable
          multiple
          :items="companyCategorys"
          label="公司（机构）类别"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col>
      <v-col
        v-if=" $route.query.formId === tjFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-select
          v-model="isindustPark"
          :items="isindustParks"
          label="产业园项目"
          item-text="name"
          item-value="code"
          solo
          dense
          clearable
        />
      </v-col>
      <v-col
        v-if="isEdit && ($route.query.formId === wsFormId || $route.query.formId === tjFormId)"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-select
          v-model="aStatusList"
          :items="aStatusLists"
          label="审核状态"
          item-text="text"
          item-value="value"
          solo
          dense
          clearable
        />
      </v-col>
      <v-col
        v-if="$route.query.formId === wsFormId || $route.query.formId === tjFormId"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <!-- <v-select
          v-model="projectStatus"
          :items="projectStatusList"
          label="项目状态"
          item-text="text"
          item-value="value"
          solo
          dense
          clearable
        /> -->
        <v-select
          v-model="projectStatus2"
          :items="projectStatus2s"
          label="项目状态"
          item-text="name"
          item-value="code"
          solo
          dense
          clearable
        />
      </v-col>
      <!-- <v-col
        v-if="orgValue === 'ROLE_TZCJCNBYJ' && ($route.query.formId === wsFormId || $route.query.formId === tjFormId)"
        md="1"
      >
        <v-select
          v-model="tzcHandle"
          clearable
          :items="handles"
          label="办理状态"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col>
      <v-col
        v-if="orgValue === 'ROLE_KFQCNBYJ' && ($route.query.formId === wsFormId || $route.query.formId === tjFormId)"
        md="1"
      >
        <v-select
          v-model="kfqHandle"
          clearable
          :items="handles"
          label="办理状态"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col>
      <v-col
        v-if="orgValue === 'ROLE_TZCJCFZRSHYJ' && ($route.query.formId === wsFormId || $route.query.formId === tjFormId)"
        md="1"
      >
        <v-select
          v-model="tzcfzrHandle"
          clearable
          :items="handles"
          label="办理状态"
          item-text="name"
          item-value="code"
          solo
          dense
        />
      </v-col> -->
      <v-col
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-text-field
          v-model="fillingUser"
          label="提报用户"
          solo
          dense
          clearable
        />
      </v-col>
      <v-col
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
      >
        <v-btn
          elevation="1"
          color="blue mt-0"
          style="max-width:100%;"
          @click="searchBtn"
        >
          <v-icon left>
            fa4 fa-search
          </v-icon>
          搜索
        </v-btn>
      </v-col>
      <v-col
        v-if="$route.query.formId && !$route.query.formId.includes(',') "
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 text-right"
      >
        <v-btn
          :loading="exporting"
          elevation="1"
          style="max-width:100%;"
          color="blue mt-0"
          @click="exportOr"
        >
          <v-icon left>
            fa4 fa-external-link
          </v-icon>
          导出
        </v-btn>
      </v-col>

      <v-col
        v-if="($route.query.formId === wsFormId || $route.query.formId === tjFormId ) && !$route.query.formId.includes(',')"
        cols="4"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 text-right"
      >
        <v-btn
          :loading="exporting2"
          elevation="1"
          :style="isXs ? 'max-width:100%;' :''"
          color="blue mt-0"
          @click="export2"
        >
          <v-icon left>
            fa4 fa-external-link
          </v-icon>
          导出进展
        </v-btn>
      </v-col>
    </v-row>
    <audioBox ref="audioBox" />
    <v-dialog
      v-model="isexport"
      width="600px"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title class="mr-10">
            请选择需要导出的内容
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            elevation="1"
            class="ma-1 mr-4"
            color="success"
            @click="allSelect"
          >
            全选
          </v-btn>
          <v-btn
            elevation="1"
            class="ma-1 mr-4"
            color="error"
            @click="allexport = []"
          >
            全不选
          </v-btn>
          <v-btn
            :loading="exporting"
            elevation="1"
            class="ma-1"
            color="#0D47A1"
            @click="exportAll"
          >
            导出
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-treeview
              v-model="allexport"
              item-key="code"
              selected-color="success"
              selectable
              :items="exportTree"
            />
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-overlay
      :value="exporting"
      opacity="0.95"
      color="#000"
      z-index="999999"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
  import form from '../../api/form';
  import file from '../../utils/file'
  export default {
    components: {
      audioBox: () => import('../base/Audio'),
    },
    props: {
      isindustry: {
        type: Boolean,
        default: false,
      },
      ispolicy: {
        type: Boolean,
        default: false,
      },
      isForm: {
        type: Boolean,
        default: false,
      },
      isAdd: {
        type: Boolean,
        default: false,
      },
      islevel: {
        type: Boolean,
        default: false,
      },
      iscity: {
        type: Boolean,
        default: false,
      },
      isareaCode: {
        type: Boolean,
        default: false,
      },
      page: {
        type: Number,
        default: 0,
      },
      size: {
        type: Number,
        default: 15,
      },
    },
    data () {
      return {
        exporting2: false,
        orgValue: '',
        createData: '',
        orgQX: false,
        allexport: [],
        exportTree: [],
        isexport: false,
        exporting: false,
        exportDisabled: false,
        formSelect: false,
        formId: '',
        statesSelect: false,
        status: '',
        states: [
          {
            text: '审核状态',
            value: '',
          },
          {
            text: '驳回',
            value: -5,
          },
          {
            text: '通过审核',
            value: 10,
          },
          {
            text: '待审核',
            value: 5,
          },
          {
            text: '暂存',
            value: 0,
          },
        ],
        projectName: '',
        customerName: '',
        fillingUser: '',
        // 产业
        industryCategory: '',
        industryCategorys: [],
        isAdmin: true,
        // 搜索参数对象
        searchParame: {},
        areaCode: '',
        city: '',
        areaCodes: [],
        citys: [],
        policyData: [],
        industryData: [],
        levelData: [],
        policyType: '',
        industry: '',
        cooperationMode: '',
        isManufacturingProject: '',
        policyLevel: '',
        investmentScale: '',
        customerLevel: '',
        headquarters: '',
        companyCategory: '',
        projectStatus: '',
        tzcHandle: '',
        kfqHandle: '',
        tzcfzrHandle: '',
        country: '',
        formTypes: [],
        isdc: true,
        industrys: [],
        countrys: [],
        cooperationModes: [],
        isManufacturingProjects: [],
        investmentScales: [],
        customerLevels: [],
        headquarterss: [],
        companyCategorys: [],
        projectStatus2: '',
        projectStatus2s: [],
        projectStatusList: [
          {
            text: '待确认',
            value: 5,
          },
          {
            text: '待完善',
            value: -5,
          },
          {
            text: '待推送',
            value: 'NULL',
          },
          { text: '已推送', value: '是' }],
        aStatusLists: [
          {
            text: '待审核',
            value: 0,
          },
          /* {
            text: '待完善',
            value: -5,
          }, */
          {
            text: '已通过',
            value: 10,
          }],
        aStatusList: '',
        isindustParks: [],
        isindustPark: '',
        handles: [{ name: '已办理', code: '已办理' }, { name: '未办理', code: 'NULL' }, { name: '待办理', code: '待办理' }, { name: '待完善', code: '待完善' }],
        projectStatuss: [],
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        // 客户
        kuFormId: 'ad326bff-dcd0-4610-95c2-3a2d6c4f165a',
        // 外商寻求
        wqFormId: 'eeadced3-7c53-4e4d-9f17-a334a28e10ad',
        // 推介和外商的审核人员
        isEdit: false,
        isXs: false,
      }
    },
    watch: {
      // 监听路由是否变化，一旦变化重新设置表单id和拉取项目列表
      $route: {
        async handler (to, from) {
          console.log('to', to, 'from', from);
          // 跳转后清空多余筛选值
          if (from && (to.query.formId !== from.query.formId)) {
            Object.keys(this.searchParame).map(val => {
              console.log('val0,', val);
              if (val !== 'formId' && val !== '_from') {
                this[val] = ''
              }
            })
          }
          // 首页跳转传值改变筛选值
          if (to.query.statusList === '-5' && to.query._from === 'org') {
            this.projectStatus2 = '待完善'
          }
          if (to.query.aStatusList === '0' && to.query._from === 'audit') {
            this.aStatusList = 0
          }
          if (to.query.aStatusList === '10' && to.query._from === 'audit') {
            this.aStatusList = 10
          }
          await this.init()
          this.$emit('search', this.searchParameData())
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
      'page' (val) {
        this.$emit('search', this.searchParameData())
      },
      'formId' (newVal, oldVal) {
        console.log('newVal.split(', ')', newVal.split(','));

        if (newVal && newVal.split(',').length === 1) {
          this.isdc = true
        } else {
          this.isdc = false
        }

        this.exportDisabled = this.exportDisabledBtn()
      },
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      await this.init()
    },
    methods: {
      async init () {
        this.industrys = await this.getdictionar('08be7c1a-f3c7-4ef3-aef6-a2f56eb82531')
        this.cooperationModes = await this.getdictionar('a020d617-8397-4546-b824-511605ac3b1f')
        this.isManufacturingProjects = await this.getdictionar('yesOrNo')
        this.isindustParks = await this.getdictionar('yesOrNo')
        this.investmentScales = await this.getdictionar('0f4adbdf-58ed-4e62-aed6-fbcd92524a66')
        this.customerLevels = await this.getdictionar('326517f0-5e87-4a07-88c0-969f61b46ff8')
        this.headquarterss = await this.getdictionar('country')
        this.companyCategorys = await this.getdictionar('8b7954b2-ec5f-402f-bc70-2e186799ba43')
        this.projectStatuss = await this.getdictionar('120a5926-2de4-498b-8780-c31d38e2e7ed')
        this.countrys = await this.getdictionar('country')
        console.log('this.countrys', this.countrys);
        this.citys = await this.getdictionar('countryTree')
        // 项目进展状态
        this.projectStatus2s = []
        var list = [{ name: '待完善', code: '待完善' }, { name: '未推送', code: 'NULL' }, { name: '已推送', code: '已推送' }]
        var List2
        if (this.$route.query.formId === this.wsFormId) {
          List2 = await this.getSelectDictData(
            'ba99ae56-7c5a-4fc0-98f0-93ea65c88878',
          );
        } else if (this.$route.query.formId === this.tjFormId) {
          List2 = await this.getSelectDictData(
            'eccd42d9-a0e3-41b6-bb29-05142e0753d2',
          );
        }
        this.projectStatus2s = list.concat(List2)
        // this.areaCodes = await this.getdictionar('5be1d969-20c5-42f5-b8e7-82bca59092ac')
        // this.policyData = await this.getdictionar('dd113144-617e-42ab-b9d5-1ac62b92e77d')

        // this.industryData = await this.getdictionar('dd113144-617e-42ab-b9d5-1ac62b92e77d')
        // this.levelData = await this.getdictionar('37a53eb1-f620-4f87-8f55-9b89240320ba')
        if (this.$route.query.formId) {
          this.formId = this.$route.query.formId
        } else {
          this.isdc = false
        }
        this.isEdit = localStorage.isEdit === 'true' || localStorage.isEdit === true
        this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
        this.isAdmin = localStorage.isAdmin === 'true' || localStorage.isAdmin === true
        if (JSON.parse(localStorage.newForms).length) {
          this.formTypes = JSON.parse(localStorage.newForms)[0].children
          const formIds = []
          this.formTypes.map(val => {
            formIds.push(val.id)
          })
          this.formTypes.unshift({ name: '信息列表', id: formIds.toString() })
          console.log('this.formTypes', this.formTypes)
          if (this.isForm) {
            this.formId = formIds.toString()
          }
        }
        await this.getMeInfo()
        this.exportDisabled = this.exportDisabledBtn()
      },
      // 获取选择列表数据
      async getSelectDictData (dictId) {
        const select = [];
        await this.$axios.get(`/dictionaries/${dictId}`).then((res) => {
          const resData = res.data.data;
          resData.map((val) => {
            select.push({
              code: val.code,
              name: val.name,
            });
          });
        });
        return select;
      },
      async getMeInfo () {
        const res = await this.$axios.get('/me')
        console.log('res', res);
        const roleName = res.data.data.roles
        // 投资促进处负责人
        if (roleName.includes('ROLE_TZCJCFZRSHYJ')) {
          this.orgValue = 'ROLE_TZCJCFZRSHYJ'
          // 开发区处
        } else if (roleName.includes('ROLE_KFQCNBYJ')) {
          this.orgValue = 'ROLE_KFQCNBYJ'
          // 投资促进处
        } else if (roleName.includes('ROLE_TZCJCNBYJ')) {
          this.orgValue = 'ROLE_TZCJCNBYJ'
        } else {
          this.orgValue = ''
        }
      },
      async formRule (formId) {
        if (formId) {
          const formData = await this.$axios.get('/forms/' + formId + '/fields')
          return formData.data.data
        }
      },
      async getdictionar (name) {
        const resp = await this.$axios.get(`/dictionaries/${name}`)
        return resp.data.data
      },
      exportDisabledBtn () {
        const formIdArr = this.formId.split(',')
        if (formIdArr.length > 1) {
          return true
        } else {
          return false
        }
      },
      // 添加项目按钮，根据表单ID跳转到项目编辑页，
      addProjcet () {
        if (this.$route.query.formId) {
          this.$router.push({
            name: 'Report',
            query: {
              formId: this.$route.query.formId,
            },
          })
        } else { // 如果没有表单ID，则直接跳转
          this.$router.push({
            name: 'Report',
          })
        }
      },
      // 搜索参数对象
      searchParameData () {
        this.searchParame = {}
        this.$route.query._from && (this.searchParame['_from'] = this.$route.query._from)
        if (this.$route.query.formId === this.wsFormId || this.$route.query.formId === this.tjFormId) {
          //   // 项目状态
          //   if (this.projectStatus === 5) {
          //     this.searchParame['status'] = 5
          //     delete this.searchParame['isPush']
          //   } else if (this.projectStatus === -5) {
          //     this.searchParame['status'] = -5
          //     delete this.searchParame['isPush']
          //   } else if (this.projectStatus === 'NULL') {
          //     this.searchParame['status'] = 10
          //     this.searchParame['isPush'] = 'NULL'
          //   } else if (this.projectStatus === '是') {
          //     this.searchParame['isPush'] = '是'
          //     delete this.searchParame['status']
          //   } else {
          //     delete this.searchParame['status']
          //     delete this.searchParame['isPush']
          //   }

          //   if (this.tzcHandle) {
          //     if (this.tzcHandle === '已办理') {
          //       delete this.searchParame['status']
          //       // this.searchParame['status'] = 10
          //       this.searchParame['tzcHandle'] = '已办理'
          //     } else if (this.tzcHandle === '待办理') {
          //       this.searchParame['status'] = 10
          //       this.searchParame['tzcHandle'] = 'NULL'
          //     } else if (this.tzcHandle === 'NULL') {
          //       // this.searchParame['status'] = '5,-5,0'
          //       this.searchParame['tzcHandle'] = 'NULL'
          //     } else if (this.tzcHandle === '待完善') {
          //       delete this.searchParame['status']
          //       this.searchParame['tzcHandle'] = '待完善'
          //     } else if (this.projectStatus) {
          //       delete this.searchParame['tzcHandle']
          //     } else {
          //       delete this.searchParame['status']
          //       delete this.searchParame['tzcHandle']
          //     }
          //   }
          //   if (this.kfqHandle) {
          //     if (this.kfqHandle === '已办理') {
          //       delete this.searchParame['status']
          //       // this.searchParame['status'] = 10
          //       this.searchParame['kfqHandle'] = '已办理'
          //     } else if (this.kfqHandle === '待办理') {
          //       this.searchParame['status'] = 10
          //       this.searchParame['kfqHandle'] = '待办理'
          //     } else if (this.kfqHandle === 'NULL') {
          //       delete this.searchParame['status']
          //       this.searchParame['kfqHandle'] = 'NULL'
          //     } else if (this.kfqHandle === '待完善') {
          //       delete this.searchParame['status']
          //       this.searchParame['kfqHandle'] = '待完善'
          //     } else if (this.projectStatus) {
          //       delete this.searchParame['kfqHandle']
          //     } else {
          //       delete this.searchParame['status']
          //       delete this.searchParame['kfqHandle']
          //     }
          //   }
          //   if (this.tzcfzrHandle) {
          //     if (this.tzcfzrHandle === '已办理') {
          //       delete this.searchParame['status']
          //       // this.searchParame['status'] = 10
          //       this.searchParame['tzcfzrHandle'] = '已办理'
          //     } else if (this.tzcfzrHandle === '待办理') {
          //       this.searchParame['status'] = 10
          //       this.searchParame['tzcfzrHandle'] = '待办理'
          //     } else if (this.tzcfzrHandle === 'NULL') {
          //       delete this.searchParame['status']
          //       this.searchParame['tzcfzrHandle'] = 'NULL'
          //     } else if (this.tzcfzrHandle === '待完善') {
          //       delete this.searchParame['status']
          //       this.searchParame['tzcfzrHandle'] = '待完善'
          //     } else if (this.projectStatus) {
          //       delete this.searchParame['tzcfzrHandle']
          //     } else {
          //       delete this.searchParame['status']
          //       delete this.searchParame['tzcfzrHandle']
          //     }
          //   }

          // 项目状态中所有待完善
          if (this.projectStatus2 === '待完善') {
            this.searchParame['statusList'] = -5
            this.searchParame['_from'] = 'org'
            this.aStatusList = ''
            delete this.searchParame['aStatusList']
            delete this.searchParame['projectStatus2']
          } else {
            this.projectStatus2 && (this.searchParame['projectStatus2'] = this.projectStatus2)
            // 审核状态
            delete this.searchParame['aStatusList'];
            // if (this.aStatusList === 0) {
            //   this.searchParame['aStatusList'] = this.aStatusList
            //   this.searchParame['_from'] = 'audit'
            // } else if (this.aStatusList) {
            //   this.searchParame['aStatusList'] = this.aStatusList
            //   this.searchParame['_from'] = 'audit'
            // } else {
            //   // 推介项目只看与自己相关
            //   if (this.formId === this.tjFormId && this.isEdit) {
            //     this.searchParame['aStatusList'] = '0,10'
            //     this.searchParame['_from'] = 'audit'
            //     this.searchParame['status'] = '5,10'
            //   } else {
            //     delete this.searchParame['status']
            //     delete this.searchParame['aStatusList']
            //     this.searchParame['_from'] = 'org'
            //   }
            // }

            // 审核人员区分后  再分推介项目
            if (this.isEdit) {
              if (this.formId === this.tjFormId) {
                this.searchParame['statusList'] = '5,10,0'
                this.searchParame['_from'] = 'audit'
                // 领导临时查看
                // this.searchParame['isShow'] = '是'
                if (this.aStatusList === 0) {
                  this.searchParame['aStatusList'] = this.aStatusList
                } else if (this.aStatusList) {
                  this.searchParame['aStatusList'] = this.aStatusList
                } else {
                  this.searchParame['aStatusList'] = '0,10'
                }
              } else {
                delete this.searchParame['aStatusList']
                delete this.searchParame['statusList']
                if (this.aStatusList === 0) {
                  this.searchParame['aStatusList'] = this.aStatusList
                  this.searchParame['_from'] = 'audit'
                } else if (this.aStatusList) {
                  this.searchParame['aStatusList'] = this.aStatusList
                  this.searchParame['_from'] = 'audit'
                } else {
                  delete this.searchParame['statusList']
                  this.searchParame['_from'] = 'org'
                }
              }
            } else {
              delete this.searchParame['statusList']
              this.searchParame['_from'] = 'org'
            }
          }
        }
        this.isindustPark && (this.searchParame['isindustPark'] = this.isindustPark)
        this.formId && (this.searchParame['formId'] = this.formId)
        this.fillingUser && (this.searchParame['fillingUser'] = this.fillingUser)
        this.areaCode && (this.searchParame['areaCode'] = this.areaCode)
        this.city && (this.searchParame['city'] = this.city)
        this.policyType && (this.searchParame['policyType'] = this.policyType)
        this.industry && (this.searchParame['industry'] = this.industry)
        this.cooperationMode && (this.searchParame['cooperationMode'] = this.cooperationMode)
        this.isManufacturingProject && (this.searchParame['isManufacturingProject'] = this.isManufacturingProject)
        this.investmentScale && (this.searchParame['investmentScale'] = this.investmentScale)
        this.customerLevel && (this.searchParame['customerLevel'] = this.customerLevel)
        this.headquarters && (this.searchParame['headquarters'] = this.headquarters)
        this.companyCategory && (this.searchParame['companyCategory'] = this.companyCategory.toString())
        this.country && (this.searchParame['country'] = this.country)
        this.projectName && (this.searchParame['name'] = this.projectName)
        this.customerName && (this.searchParame['customerName'] = this.customerName)
        if (this.createData[0]) {
          this.searchParame['_createdFrom'] = this.$moment(this.createData[0]).format('YYYY-MM-DD')
        } else {
          delete this.searchParame['_createdFrom']
        }
        if (this.createData[1]) {
          this.searchParame['_createdTo'] = this.$moment(this.createData[1]).format('YYYY-MM-DD')
        } else {
          delete this.searchParame['_createdTo']
        }
        console.log('onethis.searchParame', this.searchParame);

        return this.searchParame
      },
      // 搜索按钮
      searchBtn () {
        const searchParameObj = Object.assign(this.searchParameData())
        if (this.isForm) {
          searchParameObj.formId = this.formId
          searchParameObj.statusList = this.$route.query.statusList
          searchParameObj.astatus = this.$route.query.astatus
        } else {
          searchParameObj.formId = this.$route.query.formId
        }
        this.projectName && (searchParameObj['name'] = this.projectName)
        this.customerName && (searchParameObj['customerName'] = this.customerName)
        const path = this.$route.path
        this.$router.push({
          path: path,
          query: searchParameObj,
        })
      },
      allSelect () {
        const list = []
        this.exportTree.map(val => {
          list.push(val.code)
        })
        this.allexport = list
      },
      exportAll () {
        console.log('allexport', this.allexport);
        if (this.allexport.length === 0) {
          return this.$swal({
            title: '请选择导出内容！',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: '知道了',
          })
        }
        // 导出项
        this.exportTable({ columns: this.allexport.join(',') })
      },
      is_weixin () {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
          console.log('微信浏览器');
          return true;
        } else {
          console.log('不是微信浏览器');
          return false;
        }
      },
      async exportOr () {
        if (this.is_weixin()) {
          this.$swal({
            title: '点击右上角选择在浏览器中打开!',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          this.allexport = []
          var forms = await this.formRule(this.$route.query.formId)
          console.log('forms', forms);
          this.exportTree = forms.filter(val => {
            if (this.$route.query.formId === this.tjFormId) {
              return val.code !== 'city' && (val.type !== 'hidden' || val.code === 'fillingUser' || val.code === 'city2' || val.code === 'county2' || val.code === 'projectStatus2' || val.code === 'pushed')
            } else {
              return val.type !== 'hidden' || val.code === 'fillingUser' || val.code === 'projectStatus2' || val.code === 'pushed'
            }
          })
          // this.exportTree = forms
          this.isexport = true
        }
      },
      // 导出表格
      exportTable (headers) {
        this.searchParame = {}
        this.searchParame = this.searchParameData()
        console.log('this.searchParame1', this.searchParame);
        // delete this.searchParame.isShow
        var formId = this.formId
        if (this.isForm) {
          formId = this.formId
          this.searchParame.statusList = this.$route.query.statusList
          this.searchParame.astatus = this.$route.query.astatus
        } else {
          formId = this.$route.query.formId
          // delete this.searchParame.status
          // delete this.searchParame.astatus
        }
        console.log('this.searchParame2', this.searchParame);

        this.exporting = true
        // this.searchParame._from = 'org'
        this.exportFile('reports/' + formId + '/export', this.searchParame, headers)
      },
      // 导出文件
      async exportFile (url, params, headers) {
        let data
        try {
          data = await this.$axios.get(url, {
            responseType: 'blob',
            params: params,
            headers,
          })
        } catch (e) {
          console.log(e)
        }
        setTimeout(() => {
          this.exporting = false
        }, 1000);
        if (!data || !data.data || data.data.length < 200) {
          this.$swal({
            title: '导出失败，请稍后再试！',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: '知道了',
          })
          return
        }
        file.downFile(data.data, '导出表格')
      },
      export2 () {
        if (this.is_weixin()) {
          this.$swal({
            title: '点击右上角选择在浏览器中打开!',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          this.exporting2 = true
          this.searchParame = {}
          this.searchParame = this.searchParameData()
          console.log('this.searchParame1', this.searchParame);
          var formId = this.formId
          if (this.isForm) {
            formId = this.formId
            this.searchParame.status = this.$route.query.status
            this.searchParame.astatus = this.$route.query.astatus
          } else {
            formId = this.$route.query.formId
            delete this.searchParame.status
            delete this.searchParame.astatus
          }
          this.$axios.get(`/foreignInvestment/${this.$route.query.formId}/reports/export`)
            .then(res => {
              console.log('res', res)
              const resData = res.config.url
              this.exportFile2(resData, this.searchParame)
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
      // 导出文件
      async exportFile2 (url, params) {
        const data = await this.$axios.get(url, {
          responseType: 'blob',
          params: params,
        })
        const link = document.createElement('a')
        // link.style.display = 'none'
        link.href = window.URL ? window.URL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' })) : window.webkitURL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' }))
        link.setAttribute('download', '项目进展.xls')
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.exporting2 = false
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .ivu-input {
  height: 38px;
  border: none;
}
::v-deep .titleSearch .v-text-field__details{
display:none
}
</style>
