// 项目授权接口
import base from './base'
import axios from './http'
// import qs from 'qs' // 根据需求是否导入QS模块

const dic = {
  // 修改访问权限，权限可以是角色也可以是用户组
  putAccess (model, modelId, grant, params) {
    return axios.put(`${base.sq}/models/${model}/${modelId}/${grant}`, params)
  },
  getAccess (model, modelId) {
    return axios.get(`${base.sq}/models/${model}/${modelId}/access`)
  },
}
export default dic
