<template>
  <v-navigation-drawer
    v-model="dynamicDrawer"
    absolute
    temporary
    width="400"
    :height="height"
  >
    <v-card
      max-width="450"
      class="mt-0"
    >
      <v-toolbar
        color="cyan"
        dark
      >
        <v-toolbar-title>消息列表</v-toolbar-title>

        <v-spacer />

        <v-btn
          small
          color="white"
          text
          @click="allRead"
        >
          <v-icon
            left
            small
          >
            fa fa-check-square-o
          </v-icon>
          忽略全部
        </v-btn>
      </v-toolbar>
      <v-tabs
        v-model="messageTab"
        centered
      >
        <v-tab>
          未读消息
        </v-tab>
        <v-tab>
          已读消息
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="messageTab">
        <v-tab-item>
          <v-list three-line>
            <template v-for="(unreadMessage, index) in unreadMessages">
              <v-list-item
                v-if="unreadMessage.userInfo"
                :key="index"
              >
                <v-list-item-avatar>
                  <v-img :src="unreadMessage.userInfo.avatar ? unreadMessage.userInfo.avatar : userPhoto" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ unreadMessage.createdAt | dateformat }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ unreadMessage.content }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    v-if="unreadMessage.payload"
                    icon
                    @click="toFillinDetails(unreadMessage.payload.formId, unreadMessage.modelId, unreadMessage.id)"
                  >
                    <v-icon color="grey lighten-1">
                      fa fa-eye
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="error"
                    @click="delMessageBtn(unreadMessage.id)"
                  >
                    <v-icon color="grey lighten-1">
                      fa fa-trash-o
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <v-list three-line>
            <template v-for="(message, index) in readMessages">
              <v-list-item
                v-if="message.userInfo"
                :key="index"
              >
                <v-list-item-avatar>
                  <v-img :src="message.userInfo.avatar ? message.userInfo.avatar : userPhoto" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ message.createdAt | dateformat }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ message.content }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    v-if="message.payload"
                    icon
                    @click="toFillinDetails(message.payload.formId, message.modelId, message.id)"
                  >
                    <v-icon color="grey lighten-1">
                      fa fa-eye
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="error"
                    @click="delMessageBtn(message.id)"
                  >
                    <v-icon color="grey lighten-1">
                      fa fa-trash-o
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
  import userPhoto from '../../assets/user_photo.jpg'
  export default ({
    data () {
      return {
        unreadMessages: [],
        readMessages: [],
        messageTab: null,
        dynamicDrawer: false,
        height: '',
        MessageData: {
          length: '',
          show: '',
        },
        userPhoto: userPhoto,
      }
    },
    created () {
      this.height = window.screen.height
      this.getunreadMessages()
      this.getreadMessages()
    },
    methods: {
      open () {
        this.dynamicDrawer = true
      },
      allRead () {
        this.$axios.get('/messages', {
          params: {
            unread: true,
          },
        })
          .then(res => {
            const resData = res.data.data.content
            const _this = this
            resData.map(function (val) {
              _this.$axios.put(`/messages/${val.id}/read`)
                .then(res => {
                  _this.getunreadMessages()
                  _this.getreadMessages()
                })
            })
          })
      },
      toFillinDetails (formId, id, messageId) {
        this.$router.push({
          name: 'ReportDetails',
          params: {
            formId: formId,
            id: id,
          },
        })
        this.$axios.put(`/messages/${messageId}/read`)
          .then(res => {
            console.log(res)
            this.getunreadMessages()
            this.getreadMessages()
          })
      },

      delMessageBtn (id) {
        this.$swal({
          title: '你确定要删除此条消息吗？',
          text: '消息删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then((result) => {
            if (result.value) {
              this.delMessage(id)
            }
          })
      },

      delMessage (id) {
        this.$axios.delete('/messages/' + id)
          .then(res => {
            this.$swal({
              title: '成功删除!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getunreadMessages()
            this.getreadMessages()
          })
          .catch(err => {
            console.log(err)
          })
      },
      // 获取未读信息
      async getunreadMessages () {
        await this.$axios.get('/messages', {
          params: {
            unread: true,
          },
        })
          .then(res => {
            const _this = this
            this.unreadMessages = res.data.data.content
            this.MessageData.length = res.data.data.totalElements
            if (this.MessageData.length > 0) {
              this.MessageData.show = true
            } else {
              this.MessageData.show = false
            }
            this.$emit('Messages', this.MessageData)
            this.unreadMessages.map(async function (val) {
              await _this.$axios.get('/users/' + val.userId + '/show')
                .then(res => {
                  _this.$set(val, 'userInfo', res.data.data)
                })
                .catch(err => {
                  console.log(err)
                })
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
      // 获取已读信息
      async getreadMessages () {
        await this.$axios.get('/messages', {
          params: {
            unread: false,
          },
        })
          .then(res => {
            const _this = this
            this.readMessages = res.data.data.content
            this.readMessages.map(async function (val) {
              await _this.$axios.get('/users/' + val.userId + '/show')
                .then(res => {
                  _this.$set(val, 'userInfo', res.data.data)
                })
                .catch(err => {
                  console.log(err)
                })
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  })
</script>
