import permission from './permission'
import notPermission from './notPermission'
import copy from './copy'
import debounce from './debounce'
import lazyLoad from './lazyLoad'

// 自定义指令
const directives = {
  // 权限指令
  permission,
  // 快捷复制指令
  copy,
  // 按钮防抖
  debounce,
  // 资源懒加载
  lazyLoad,
  notPermission,
}

export default {
  install (Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key])
    })
  },
}
