import axios from 'Axios'
import router from '../router'
import vueAlert from 'sweetalert2'

// 用户sweetalert2弹出错误提示
const tip = msg => {
  vueAlert.fire({
    title: msg,
    type: 'error',
    confirmButtonText: 'Cool',
  })
}

// 带着当前页面的路由，跳到登陆页，为登录后跳回当前页提供数据
const toLogin = () => {
  router.replace({
    path: '/pages/login',
    query: {
      redirect: router.currentRouter.fullPath,
    },
  })
}

// 错误状态判断，传入状态码和其他信息
const errorHandle = (status, other) => {
console.log('status, other', status, other)

  switch (status) {
    case 401:
      toLogin()
      break
    case 404:
     // tip('请求的资源不存在')
      break
    default:
      console.log(other)
  }
}

// 创建axios实例
const instance = axios.create({
  // 请求超时定为12秒
  timeout: 1000 * 12,
})

// 请求拦截器,每次请求前，如果存在TOKEN则在请求头中携带TOKEN
instance.interceptors.request.use(
  // 成功后执行的方法
  config => {
    // 登陆流程控制中，根据本地是否存在token判断用户的登陆情况
    // 但是即使token存在，也有可能是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登陆情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    // 从本地存储中获取token
    const token = localStorage.token
    // 如果存在Token,就在头部信息中添加Token
    token && (config.headers.Authorization = token)
    // 添加APPkey
    config.headers['x-app'] = 'webLxRPcVftl1'
    config.headers['x-origin'] = 'http://zdxm.sdcom.gov.cn'
    return config
  },
  // 失败后执行的方法
  error => Promise.error(error),
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => res.status === 200 ? Promise.resolve(res) : Promise.reject(res),
  // 请求失败
  error => {
    console.log(error)
    const { response } = error
    if (response) {
      // 根据状态输出信息
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      // 处理断网情况
      // 请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        console.log('说明')
      } else {
        return Promise.reject(error)
      }
    }
  })

  export default instance
