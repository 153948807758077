// 表单接口
import base from './base'
import axios from './http'
// import qs from 'qs' // 根据需求是否导入QS模块

const form = {
  addForm (formInfo) {
    return axios.post(`${base.sq}/forms`, formInfo)
  },
  delForm (id) {
    return axios.delete(`${base.sq}/forms/${id}`)
  },
  upForm (id, formInfo) {
    return axios.put(`${base.sq}/forms/${id}`, formInfo)
  },
  getForms () {
    return axios.get(`${base.sq}/forms/`)
  },
  copyForm (id, fromInfo) {
    return axios.post(`${base.sq}/forms/${id}/clone`, fromInfo)
  },
  // 获取表单字段统计
  getStatuses (reprotId) {
    return axios.get(`${base.sq}/forms/${reprotId}/statuses2`)
  },
  // 获取表单字段统计
  getformsContent (reprotId) {
    return axios.get(`${base.sq}/forms/${reprotId}`)
  },
  getFormFields (formId) {
    return axios.get(`${base.sq}/forms/${formId}/fields`)
  },
  importForm (formId, file) {
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      return axios.post(`${base.sq}/forms/${formId}/import`, formData, {
        headers: { 'Content-Type': 'multipart/form-data; boundary=' + new Date().getTime() },
      })
    }
  },
  exportTemplates (formId) {
    return axios.get(`${base.sq}/forms/${formId}/excel`, {
      responseType: 'blob',
    });
  },
}
export default form
