// 登录管理
import base from './base'
import axios from './http'
const login = {
  password (username, password) {
    return axios.post(`${base.sq}/auth/login/encrypt`, {
      username: username,
      password: password,
    })
  },
}
export default login
