// 用户管理
import base from './base'
import axios from './http'

const user = {
  getUserInfo (params) {
    return axios.get(`${base.sq}/me`, {
      params: params,
    })
  },
  batchCreation (id, data) {
    return axios.post(`${base.sq}/sites/${id}/users`, data)
  },
}
export default user
